<template>
  <div
    class="open_banner_padding container grid grid-cols-12 gap-[20px] pb-[20px] pt-[36px] lg:pb-[40px] lg:pt-[20px]"
  >
    <CommonLeftMenu
      class="col-span-3 hidden max-w-[290px] lg:col-span-2 xl:block"
    />
    <div class="col-span-full flex max-w-[1530px] flex-col xl:col-span-10">
      <MainPagePinnedElementsBlock
        v-if="pinned?.data?.value?.length"
        :data="pinnedMapped"
        class="mb-[80px]"
      />

      <MainPageUnknownWarBlock
        v-if="unknownWarReverse?.length"
        :data="unknownWarReverse"
        class="mb-[80px]"
      />

      <MainPageTestBlock v-if="false" class="mb-[80px]" />

      <MainPageNewsBlock
        v-if="news?.data?.value?.data?.length"
        :data="news.data?.value?.data"
        class="mb-[80px]"
      />

      <MainPagePresentationBlock class="mb-[80px]" />

      <MainPageMyHistoryArticlesBlock class="mb-[80px]" />

      <MainPageRandomVideosBlock
        v-if="videoMaterials?.data?.value?.length"
        :data="videoMaterials.data.value"
        class="mb-[80px]"
      />

      <!-- <MainPageSummerArticlesBlock class="mb-[80px]" /> -->

      <MainPageThisDayBlock
        v-if="
          thisDayInHistory?.data?.value &&
          Object.values(thisDayInHistory?.data?.value)?.length
        "
        :data="thisDayInHistory?.data?.value"
        class="mb-[80px]"
      />

      <MainPagePopularArticlesBlock class="mb-[80px]" />

      <ClientOnly>
        <MainPageThemeOfTheWeekBlock
          :data="projects"
          class="mb-[80px]"
          title="Проекты"
        />
      </ClientOnly>

      <MainPageScienceBlock class="mb-[80px]" />

      <MainPageCollectionsBlock
        v-if="thematicCollections?.data?.value?.length"
        :data="thematicCollections?.data?.value"
        title="Тематические подборки"
        class="mb-[80px]"
      />

      <MainPageRvioProjectsBlock class="mb-[40px]" />

      <MainPageMilitaryLibraryBlock class="mb-[80px]" />

      <MainPageFilmsBlock
        v-if="filmsMaterials?.data?.value?.length"
        :data="filmsMaterials?.data?.value"
        class="mb-[80px]"
      />

      <MainPageAudioBlock />

      <MainPageKonchalovskyBlock v-if="false" />

      <!-- <Transition name="fade-modal">
        <ModalsModalNewYear />
      </Transition> -->
    </div>
  </div>
</template>

<script setup>
import projects from '@/assets/data/special-projects/projects';

definePageMeta({
  layout: 'marginless',
});

const { videoMaterials } = useVideoMaterials({ staleTime: 0 });
const { films: unknownWar } = useFilms({
  filters: { categgory: 21 },
  per_page: 20,
});
const { filmsMaterials } = useFilmsMaterials();
const { news } = useNews({ per_page: 4, isMainPage: true });
const { pinned } = usePinnedElements({ staleTime: 0 });
const { thematicCollections } = useThematicCollection({ staleTime: 0 });
const { thisDayInHistory } = useThisDayInHistory({ staleTime: 0 });

const pinnedMapped = computed(() =>
  pinned?.data?.value?.map((item) => {
    return [
      item.pinnedElement,
      ...(item.chields?.map((child) => child.pinnedElement) ?? []),
    ];
  }),
);

const unknownWarReverse = computed(() => {
  if (!unknownWar?.data?.value?.data) return [];
  return [...unknownWar.data.value.data].reverse();
});

useHead({
  title: 'Федеральный портал Истории России',
  meta: [
    {
      hid: 'keywords',
      name: 'keywords',
      content: 'Федеральный портал Истории России',
    },
  ],
});
</script>
