<template>
  <MainPageBlockWrapperNew
    title=" «Великая Отечественная. Неизвестная война» (The Unknown War)"
    href="/watch/unknown-war"
  >
    <div>
      <ClientOnly>
        <Swiper
          :slides-per-view="1"
          :space-between="20"
          :breakpoints="breakpoints"
          @swiper="onSwiper"
        >
          <SwiperSlide v-for="(item, index) in data" :key="item.id || index">
            <MainPageCardsReworkedVideoCard
              :data="item"
              @open:video="handleOpenVideo(item)"
            />
          </SwiperSlide>
        </Swiper>
      </ClientOnly>
    </div>

    <SlidersSliderControl
      type="prev"
      class="absolute left-[-16px] top-45% z-10 hidden md:block"
      :class="isBeginning ? 'opacity-50' : 'cursor-pointer'"
      @click="slidePrev()"
    />
    <SlidersSliderControl
      type="next"
      class="absolute right-[-16px] top-45% z-10 hidden md:block"
      :class="isEnd ? 'opacity-50' : 'cursor-pointer'"
      @click="slideNext()"
    />
  </MainPageBlockWrapperNew>
</template>

<script setup>
import 'swiper/css';

import { Fancybox } from 'fancyapps-ui';
import { Swiper, SwiperSlide } from 'swiper/vue';
defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});

const { onSwiper, slideNext, slidePrev, isEnd, isBeginning } = useSwiper();

const breakpoints = {
  1024: {
    slidesPerView: 2,
  },
  1280: {
    slidesPerView: 3,
  },
};

const handleOpenVideo = (item) => {
  new Fancybox([
    {
      src: item.video_code,
      caption: item.title,
      type: 'iframe',
      preload: false,
      Html: {
        autoSize: false,
      },
      customClass: 'my-iframe',
    },
  ]);
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  @apply hidden lg:block;
}
</style>
